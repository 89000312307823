<template>
	<div class="page">
		<div class="container">
			<div class="notes">请选择您的行业属性</div>
			<div class="content">
				<!-- <ul class="list">
					<li v-for="(item, index) in data.list" :key="index" :style="{color: data.selected === item.id ? style.mainColor : '' }" @click="choose(item.id)">{{item.industry_name}}</li>
				</ul> -->
				<van-collapse v-model="activeNames" class="radius">
					<template v-for="(item, index) in list">
						<van-collapse-item v-if="item.children" :key="item.id" :name="item.id">
							<template #title>
								<div class="flex justify-center industry-item" :style="{border: index === 0 ? 'none' : ''}">
									{{item.industry_name}}
								</div>
							</template>
							<template #default>
								<div v-for="(e) in item.children" :key="e.id" class="sub-item" :style="{color: data.selected == e.id ? style.mainColor : '#9F9D9D' }" @click="choose(e.id)">
									{{e.industry_name}}
								</div>
							</template>
						</van-collapse-item>
						<div v-else :key="item.id" class="industry-item" :style="{color: data.selected == item.id ? style.mainColor : '' }" @click="choose(item.id)">
							{{item.industry_name}}
						</div>
					</template>
				</van-collapse>
			</div>
		</div>
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level"></bottom>
		</div>
	</div>
</template>

<script>
	import commonMixin from '@/view/process/mixin/common.js'
	import industrySelectMixin from '@/view/process/mixin/industrySelect.js'

	export default {
		mixins: [commonMixin, industrySelectMixin],
		data() {
			return {

			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
		},
		methods: {
			
		},
	}
</script>

<style lang="less" scoped>
	@color: #9F9D9D;
	
	.notes {
		margin: 6vh auto 6vh;
		text-align: center;
		font-size: 24px;
		color: @color;
	}
	.content {
		
		/deep/ .van-collapse {
			background-color: #fff;
			width: 240px;
			margin: 0 auto;
			
			.van-cell {
			  padding: 0;
				align-items: center;
			}
			.van-cell::after {
			    border-bottom: none;
			}
			.van-icon {
				position: absolute;
				right: 15px;
			}
			
			.van-collapse-item__content {
				padding: 0;
				margin-top: -5px;
				margin-bottom: 5px;
			}
			
			.van-collapse-item--border::after {
			  // border-top: 1Px solid #e0e0e0;
				border-top: none;
			}
			
		}
		
		.industry-item {
			margin: 0 15px;
		  font-size: 17px;
		  height: 50px;
		  line-height: 50px;
		  text-align: center;
		  letter-spacing: 1Px;
		  font-family: Source Han Sans CN;
		  font-weight: 400;
			border-top: 1Px solid #e0e0e0;
		}
		
		.sub-item {
		  text-align: center;
		  font-size: 16px;
			height: 50px;
		  line-height: 50px;
		  font-family: Source Han Sans CN;
		  font-weight: 400;
		}
		
	}
	
	.list {
		width: 240px;
		margin: 0 auto;
		background-color: #fff;
		padding: 10px 20px;
		border-radius: 8px;

		li {
			color: #333;
			text-align: center;
			font-size: 20px;
			height: 55px;
			line-height: 55px;
		}

		li+li {
			border-top: 1PX solid #ddd;
		}
	}
	
</style>
